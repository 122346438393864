<template>
  <div>
    <van-popup v-model="show_" :safe-area-inset-bottom="true" :overlay="showCover" @close="closePopup" position="bottom" :close-on-click-overlay="false" :lock-scroll="true" :closeable="true">
      <div class="content">
        <div class="w-content">
          <div class="title f36 fw6">确认订单</div>
          <div class="pay-item f24 row-between-center">
            <div class="left">购买内容</div>
            <div class="left fw6">{{ payData.name }}</div>
          </div>
          <div class="pay-item f24 row-between-center">
            <div class="left">使用门店范围</div>
            <div class="left fw6">{{ payData.applyCityName }}</div>
          </div>
          <div class="pay-item f24 row-between-center">
            <div class="left">原价</div>
            <div>
              <span class="s1 fw6">¥{{ payData.originPrice }}</span>
            </div>
          </div>
          <div class="pay-item f24 row-between-center">
            <div class="left">旧卡抵扣</div>
            <div>
              <span class="s2 fw6">¥{{ payData.oldPayPrice }}</span>
            </div>
          </div>
          <div class="pay-item f24 row-between-center">
            <div class="left">升级应付价</div>
            <div>
              <span class="s1 fw6">¥{{ payData.mendPrice }}</span>
            </div>
          </div>
        </div>
        <div class="bar"></div>
        <div class="w-content">
          <div class="pay-type f24 fw6">选择支付方式</div>
          <!-- <div class="wechat f24 row-between-center" @click="payType = 1" :class="{ 'active': payType === 1 }">
            <div class="left"><img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png" alt="">
              微信支付
            </div>
          </div>
          <div v-if="appTypeStr !== 'mini'" class="alipay f24 row-between-center" @click="payType = 2" :class="{ 'active': payType === 2 }">
            <div class="left"><img src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png" alt="">支付宝支付
            </div>
          </div> -->
          <template v-for="item in payTypeList">
            <div v-if="item.useEnv.includes(appTypeStr)" :key="item.payType" @click="payType = item.payType" :class="['pay-checked', 'f24', 'row-between-center', payType === item.payType ? 'active' : '']">
              <div class="left">
                <img :src="item.icon" alt="" />
                {{ item.name }}
              </div>
            </div>
          </template>

          <div @click="pay()" class="pay-btn row-center-center fw6">￥{{ payData.mendPrice }} 去支付</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import userMixin from '@/mixin/userMixin'
import { compareVersion } from "@/lib/utils"
import { silentAppPay, getAppVersion } from '@/lib/appMethod'
import wx from 'weixin-js-sdk'
export default {
  mixins: [userMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    payData: {
      type: Object,
    },
    equityCardUserId: {
      type: Number,
    },
    showCover: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      payType: 1, // 支付类型 默认1， 只在小程序内使用，默认只有微信
      show_: false,
      version: '4.34.10',
    }
  },
  computed: {
    payTypeList() {
      if (compareVersion(this.version, '4.35.10') < 0) {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
        ]
      } else {
        return [
          { payType: 1, name: '微信支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/wechatpay.png', useEnv: ['and', 'ios', 'mini'] },
          { payType: 2, name: '支付宝支付', icon: 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/alipay.png', useEnv: ['and', 'ios'] },
          { payType: 3, name: '云闪付支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay2.png', useEnv: ['and', 'ios'] },
          // { payType: 4, name: '云闪付免密支付', icon: 'https://img.chaolu.com.cn/ACT/icon/unionpay1.png', useEnv: ['and', 'ios', 'mini'] },
        ]
      }
    },
  },
  async created() {
    this.version = await getAppVersion()
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },

    pay() {
      const url = `/pages/webView/index?webUrl=${window.location.origin}/#/superdeer-activity/sell-membership/${ this.payData.activeId ? `monthcard-upgrade&activeId=${this.payData.activeId}` : 'coach-upgrade' }&userId=1`
      const payData = {
        subject: this.payData.itemName,
        extendsJson: {
          appVersion: '2.1.11',
          userCardId: this.equityCardUserId,
          upgradeSource: this.payData.activeId ? 'ACTIVE_UPGRADE' : 'PER_UPGRADE',
          shopId: this.$route.query.id,
          activeId: this.payData.activeId
        },
        totalAmount: this.payData.mendPrice + '',
        venueId: '31',
        goodsNum: 1,
        mouldId: this.payData.itemId,
        isApp: '1',
        type: this.payData.activeId ? '28' : '26', // 礼品卡12  课包10 实体卡22 私教升级26 月卡升级28
        userId: this.userId,
        token: this.token,
        extendsType: '4',
        whetherPrivacy: 0,
        originalPriceStr: '',
        title: this.appTypeStr === 'mini' ? '商品名称' : this.payData.itemName,
      }

      this.closePopup()
      silentAppPay(this.payType, payData, url)
    },
  },
  watch: {
    show(v) {
      this.show_ = v
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  box-sizing: border-box;
  overflow-y: scroll;
}

.w-content {
  padding: 0 32px;
}
.bar {
  width: 100%;
  height: 16px;
  margin: 48px 0 0;
  background: #f5f5f5;
}
.coupon-box {
  background: #f5f5f5;
  padding-bottom: 0;
  height: 826px;
}

.pay-btn {
  width: 686px;
  height: 96px;
  background: #ffde00;
  border-radius: 8px;
  color: #242831;
  font-size: 32px;
  margin-top: 72px;
  margin-bottom: 38px;
}

.title {
  color: #242831;
  padding: 36px 0 12px;
}

.pay-type {
  padding: 48px 0 8px;
}

.pay-checked {
  background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/normal.png') no-repeat center right;
  background-size: 40px 40px;
  padding: 24px 0;

  &.active {
    background-image: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/cash-coupon/active.png');
  }

  div {
    display: flex;
    align-items: center;
  }

  img {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}

.pay-item {
  margin-top: 36px;

  .s2 {
    color: #f03c18;
  }
}
</style>
